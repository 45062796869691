import { Badge, Text, Grid, HStack } from "@chakra-ui/react";
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";
import { useCallback, useMemo, useState } from "react";
import { LabeledTextField } from "components/LabeledTextField";

export const currencyFormatOptions = {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
};

const PassStatCell = (props) => <LabeledTextField {...props} />;

const avgMinMaxDescription = (avg, min, max, options, locale = "en-US") => {
  const minFormatted = min?.toLocaleString(locale, options) || "NA";
  const maxFormatted = max?.toLocaleString(locale, options) || "NA";
  const avgFormatted = avg?.toLocaleString(locale, options) || "NA";
  return (
    <Text>
      {avgFormatted} ({minFormatted} - {maxFormatted})
    </Text>
  );
};

const tradeDetailDescription = (trade, value, options, locale = "en-US") => {
  return (
    <Text>
      {trade} ({value?.toLocaleString(locale, options) || ""})
    </Text>
  );
};

const MarketsDescription = ({ markets, mainMarket }) => {
  const limit = 10;
  const [showAllMarkets, setShowAll] = useState(markets.length <= limit);
  const m = useMemo(() => {
    if (showAllMarkets) {
      return markets;
    }
    return markets.slice(0, limit);
  }, [markets, showAllMarkets]);
  return (
    <HStack
      flexWrap={showAllMarkets ? "wrap" : "nowrap"}
      maxHeight={showAllMarkets ? "100vh" : "1rem"}
      transition={"max-height 0.4s"}
      overflow={"hidden"}
    >
      {m.map((market) => (
        <Badge
          display={"inline-block"}
          key={market}
          color={market === mainMarket ? "red" : null}
        >
          {market}
        </Badge>
      ))}
      {!showAllMarkets && (
        <Badge
          display={"inline-block"}
          cursor={"pointer"}
          onClick={() => setShowAll(true)}
        >
          ...
        </Badge>
      )}
    </HStack>
  );
};

export const PassStats = ({ title, account }) => {
  const { ftmsBreaches, pass } = account;

  const oldestFtmsBreach = ftmsBreaches?.reduce(
    (prev, curr) => (prev?.breachedAt < curr?.breachedAt ? curr : prev),
    null
  );
  const renderStatsGrid = useCallback(
    (stats) => {
      if (!stats) {
        return (
          <p>
            Stats are missing for this Pass. Contact me to raise this issue:
            <br />
            <a href="mailto:gunter@fundedtradingplus.com">
              gunter@fundedtradingplus.com
            </a>
          </p>
        );
      } else {
        return (
          <Grid
            templateColumns={{
              base: "repeat(2, 1fr)",
              "2xl": "repeat(3, 1fr)",
            }}
            gap={1}
            w="100%"
          >
            <PassStatCell
              title="Markets"
              value={
                <MarketsDescription
                  markets={pass.stats.marketsTraded}
                  mainMarket={pass.stats.mostTradedAsset}
                />
              }
            />
            <PassStatCell title="Days" value={pass.stats.daysToPass} />
            <PassStatCell title="Trades" value={pass.stats.totalTrades} />
            <PassStatCell
              title="Win Rate"
              value={pass.stats.winRate.toLocaleString("en-GB", {
                style: "percent",
              })}
            />

            <PassStatCell
              title="Avg. Lot Size (Min. - Max.)"
              value={avgMinMaxDescription(
                pass.stats.avgLotSize,
                pass.stats.minLotSize,
                pass.stats.maxLotSize
              )}
            />
            <PassStatCell
              title="Avg. Trade Duration (Min. - Max.)"
              value={avgMinMaxDescription(
                pass.stats.avgTradeDuration,
                pass.stats.minTradeDuration,
                pass.stats.maxTradeDuration,
                { maximumFractionDigits: 0 }
              )}
            />
            <PassStatCell
              title="Avg. Winning Trades (Min. - Max.)"
              value={avgMinMaxDescription(
                pass.stats.avgWinningTrade,
                0,
                pass.stats.biggestWinningTradeValue,
                currencyFormatOptions
              )}
            />
            <PassStatCell
              title="Biggest Win"
              value={tradeDetailDescription(
                pass.stats.biggestWinningTrade,
                pass.stats.biggestWinningTradeValue,
                currencyFormatOptions
              )}
            />
            <PassStatCell
              title="Avg. Losing Trades (Min. - Max.)"
              value={avgMinMaxDescription(
                pass.stats.avgLoosingTrade,
                pass.stats.biggestLoosingTradeValue,
                0,
                currencyFormatOptions
              )}
            />
            <PassStatCell
              title="Biggest Loss"
              value={tradeDetailDescription(
                pass.stats.biggestLoosingTrade,
                pass.stats.biggestLoosingTradeValue,
                currencyFormatOptions
              )}
            />
            {oldestFtmsBreach && (
              <PassStatCell
                title="Ftms Breach"
                value={oldestFtmsBreach?.timestamp}
              />
            )}
            {account.lastSyncedAt && (
              <PassStatCell
                title="Last Synced"
                value={new Date(account.lastSyncedAt).toLocaleString()}
              />
            )}
          </Grid>
        );
      }
    },
    [pass, oldestFtmsBreach]
  );

  return (
    <GroupingContainer title={title} w="100%">
      {renderStatsGrid(pass?.stats)}
    </GroupingContainer>
  );
};
