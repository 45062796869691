import React from "react";
import BasicMetricView from "./BasicMetricView";
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";
import DateTime from "components/DateTime";
import CopyTradingReport from "components/CopyTradingReport";
import { formatMoney } from "variables/utils";
import CopyComponent from "components/CopyComponent";
import { Text } from "@chakra-ui/react";
import {
  getAccountSize,
  getTotalWinnings,
  getTradeProfit,
} from "./StatusViewRequestReview";

const StatusViewUnused = ({ data: row }) => {
  return (
    <>
      <GroupingContainer flex={1} title={"Overview"} style={{ minWidth: 350 }}>
        <BasicMetricView
          data={[
            {
              title: "Payout Form",
              items: [
                {
                  label: "Date Sent",
                  value: <DateTime isoString={row?.formSentAt} />,
                },
              ],
            },

            {
              title: "Copy Trading Report",
              items: [
                {
                  label: "Updated At",
                  value: new Date(
                    row?.account?.report?.timeUpdated * 1000
                  )?.toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }),
                },
                {
                  label: "Analysed",
                  value:
                    row?.account?.report?.copyTradeReports[0]?.analyzedTrades,
                },
                {
                  label: "Similar",
                  value:
                    row?.account?.report?.copyTradeReports[0]
                      ?.countWithSimilarTrades,
                },
                {
                  label: "Account Size",
                  value: isNaN(getAccountSize(row)) ? undefined : (
                    <CopyComponent
                      component={
                        <Text>{formatMoney(getAccountSize(row))}</Text>
                      }
                      value={`${formatMoney(getAccountSize(row))}`}
                    />
                  ),
                },
                {
                  label: "Trade Profit",
                  value: isNaN(getTradeProfit(row))
                    ? undefined
                    : `${formatMoney(getTradeProfit(row))}`,
                },
                {
                  label: "Total Winnings",
                  value: isNaN(getTotalWinnings(row))
                    ? undefined
                    : `${formatMoney(getTotalWinnings(row))}`,
                },
              ],
            },
          ]}
        />
      </GroupingContainer>
      <GroupingContainer flex={1} title={"Copy Trade Report"}>
        <CopyTradingReport
          data={{
            report: row?.account?.report?.copyTradeReports[0],
            account: row?.account,
          }}
        />
      </GroupingContainer>
    </>
  );
};

export default StatusViewUnused;
