import React from "react";
import BasicMetricView from "./BasicMetricView";
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";
import DateTime from "components/DateTime";
import CopyTradingReport from "components/CopyTradingReport";
import { formatMoney } from "variables/utils";
import CopyComponent from "components/CopyComponent";
import { Text } from "@chakra-ui/react";
import {
  getAccountSize,
  getTotalWinnings,
  getTradeProfit,
} from "./StatusViewRequestReview";

const StatusViewFinalized = ({ data: row }) => {
  return (
    <>
      <GroupingContainer flex={1} title={"Information"}>
        <BasicMetricView
          data={[
            {
              title: "Payee Details",
              items: [
                {
                  label: "Name",
                  value:
                    row.personalDetails?.firstName +
                    " " +
                    row.personalDetails?.lastName,
                },
                { label: "Email", value: row?.email },
                {
                  label: "Street Address",
                  value: row.personalDetails?.address?.street,
                },
                {
                  label: "Postal Code",
                  value: row.personalDetails?.address?.zip,
                },
                {
                  label: "Region",
                  value: row.personalDetails?.address?.region,
                },
                {
                  label: "Country",
                  value: row.personalDetails?.address?.country,
                },
                { label: "Phone", value: row.personalDetails?.phone || "N/A" },
              ],
            },

            {
              title: "Payment Details",
              items: [
                { label: "Payment Method", value: row?.payoutMethod },
                {
                  label: "Account Number",
                  value: row?.payoutDetails?.find(
                    (detail) => detail.type === "Account Number"
                  )?.value,
                },
                {
                  label: "IBAN",
                  value: row?.payoutDetails?.find(
                    (detail) => detail.type === "IBAN"
                  )?.value,
                },
                {
                  label: "BIC / Swift",
                  value: row?.payoutDetails?.find(
                    (detail) => detail.type === "BIC" || detail.type === "Swift"
                  )?.value,
                },
                { label: "Bank Location", value: row?.bankCountry },
                {
                  label: "Paid On",
                  value: <DateTime isoString={row?.paidAt} />,
                },
              ],
            },
          ]}
        />
      </GroupingContainer>

      <GroupingContainer flex={1} title={"Overview"} style={{ minWidth: 350 }}>
        <BasicMetricView
          data={[
            {
              title: "Form",
              items: [
                {
                  label: "Date Sent",
                  value: <DateTime isoString={row?.formSentAt} />,
                },
              ],
            },

            {
              title: "Report",
              items: [
                {
                  label: "Updated At",
                  value: new Date(
                    row?.account?.report?.timeUpdated * 1000
                  )?.toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }),
                },

                {
                  label: "Analysed",
                  value:
                    row?.account?.report?.copyTradeReports[0]?.analyzedTrades,
                },
                {
                  label: "Similar",
                  value:
                    row?.account?.report?.copyTradeReports[0]
                      ?.countWithSimilarTrades,
                },
                {
                  label: "Account Size",
                  value: isNaN(getAccountSize(row)) ? undefined : (
                    <CopyComponent
                      component={
                        <Text>{formatMoney(getAccountSize(row))}</Text>
                      }
                      value={`${formatMoney(getAccountSize(row))}`}
                    />
                  ),
                },
                {
                  label: "Trade Profit",
                  value: isNaN(getTradeProfit(row))
                    ? undefined
                    : `${formatMoney(getTradeProfit(row))}`,
                },
                {
                  label: "Total Winnings",
                  value: isNaN(getTotalWinnings(row))
                    ? undefined
                    : `${formatMoney(getTotalWinnings(row))}`,
                },
              ],
            },
          ]}
        />
      </GroupingContainer>
      <GroupingContainer flex={1} title={"Copy Trade Report"}>
        <CopyTradingReport
          data={{
            report: row?.account?.report?.copyTradeReports[0],
            account: row?.account,
          }}
        />
      </GroupingContainer>
    </>
  );
};

export default StatusViewFinalized;
