import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdMoreVert } from "react-icons/md";

export const ActionPopover = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="vertical-nav" onClick={() => setIsOpen(!isOpen)}>
      <Popover>
        <PopoverTrigger>
          <div style={{ cursor: "pointer" }}>
            <MdMoreVert />
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>{title}</PopoverHeader>
          <PopoverBody>{children}</PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
};
