import {
  Badge,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  QueryClientProvider,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Client } from "api/axios";
import debounce from "lodash.debounce";
import React, { useCallback } from "react";
import {
  MdAdd,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdOpenInNew,
  MdSearch,
} from "react-icons/md";
import { Link } from "react-router-dom";
import Pagination, { PAGE_SIZES } from "components/pagination/pagination";
import ExpandableRow from "./components/ExpandableRows";
import NewPayout from "./components/Modals/NewPayout";
import {
  PAYMENT_STATUS_TYPES,
  STATUS_TYPES,
  checkboxStatusItems,
} from "./constants";
import { formatMoney } from "variables/utils";
import FtpBadge from "components/FtpBadge";
import DateTime from "components/DateTime";
import CheckboxMenu from "components/CheckboxMenu";
import AccountAbstract from "components/AccountAbstract";

const BannedStatus = ({ payout }) => {
  if (
    (payout.purpose.toLowerCase().includes("researcher") &&
      payout.client?.banned?.researcher) ||
    (payout.purpose.toLowerCase().includes("affiliate") &&
      payout.client?.banned?.affiliate)
  ) {
    return (
      <Badge colorScheme="red" mr={1}>
        Banned
      </Badge>
    );
  }
  return null;
};

const initialFilterState = {
  status: [STATUS_TYPES.REQUEST.API_KEY, STATUS_TYPES.AWAITING_PAYMENT.API_KEY],
};

export default function Payouts() {
  const columnHelper = createColumnHelper();
  const [pageSize, setPageSize] = React.useState(PAGE_SIZES["md"]);
  const [page, setPage] = React.useState(1);
  const [searchFilter, setSearchFilter] = React.useState("");
  const debouncedSetFilter = debounce(setSearchFilter, 500);
  const [filters, setFilters] = React.useState(initialFilterState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();

  async function fetchPaymentData() {
    // for filters.status get {key}=${value}
    let filterQuery = filters.status
      .map((item) => {
        return `status=${item}`;
      })
      .join("&");
    if (filterQuery) {
      filterQuery = `&${filterQuery}`;
    }

    let searchQuery = "";
    if (searchFilter !== "") {
      searchQuery = `&filter=${searchFilter}`;
    }
    const apiUrl = `/api/payouts?sort=createdAt&limit=${pageSize}&page=${page}&populate=account>ftmsBreaches&populate=account>report&populate=client${filterQuery}${searchQuery}`;
    return await Client(true).get(apiUrl);
  }

  const { data: queryData, isFetched } = useQuery({
    queryKey: [
      `/api/payouts/`,
      { page },
      { pageSize },
      { filters },
      { searchFilter },
    ],
    queryFn: fetchPaymentData,
    keepPreviousData: true,
    initialData: { queryData: { docs: [] } },
  });

  const accountDetails = useCallback((row) => {
    if (!row.original.account) {
      return <Text>N/A</Text>;
    }
    return (
      <AccountAbstract
        account={row.original.account}
        showDescription
        showFtmsBreach
      />
    );
  }, []);

  const tableColumns = [
    {
      id: "expander",
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <Button
            size="xs"
            p={"2px !important"}
            onClick={row.getToggleExpandedHandler()}
            cursor="pointer"
          >
            {row.getIsExpanded() ? (
              <MdKeyboardArrowDown />
            ) : (
              <MdKeyboardArrowRight />
            )}
          </Button>
        ) : (
          ""
        );
      },
    },
    columnHelper.accessor("name", {
      header: "Client",
      cell: ({ row }) =>
        row.original ? (
          <HStack>
            <VStack alignItems="left" w="full">
              <Text fontWeight={"bold"} display={"flex"}>
                {row.original.name}
              </Text>
              <Text
                fontWeight={"light"}
                wordBreak={"break-all"}
                noOfLines={2}
                mt="2px !important"
                fontSize={"sm"}
              >
                {row.original.email}
                <BannedStatus payout={row.original} />
              </Text>
            </VStack>
            <VStack>
              {row.original.client ? (
                <Link to={`/admin/clients/${row.original.client}`}>
                  <MdOpenInNew size="18px" color={"purple.500"} />
                </Link>
              ) : null}
            </VStack>
          </HStack>
        ) : (
          row.groupByVal
        ),
    }),
    columnHelper.accessor("id", {
      header: "Identifier",
      cell: ({ row }) => (
        <>
          {!row.original.externalId && (
            <FtpBadge short={true} value={row.original.id}>
              {row.original.id}
            </FtpBadge>
          )}
          {row.original.externalId && (
            <FtpBadge value={row.original.externalId} colorScheme={"green"}>
              {row.original.externalId}
            </FtpBadge>
          )}
        </>
      ),
    }),
    columnHelper.accessor("account", {
      header: "Account",
      cell: ({ row }) => accountDetails(row),
    }),
    columnHelper.accessor("amount", {
      header: "Amount",
      cell: ({ row }) => (
        <Text wordBreak={"break-all"} noOfLines={2}>
          {formatMoney(row.original.amount)}
        </Text>
      ),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: ({ row }) => (
        <Badge
          w="full"
          textAlign="center"
          colorScheme={
            PAYMENT_STATUS_TYPES[row.original.status].colorScheme || "yellow"
          }
          fontWeight={900}
        >
          {PAYMENT_STATUS_TYPES[row.original.status].label}
        </Badge>
      ),
    }),
    columnHelper.accessor("purpose", {
      header: "Type",
      cell: ({ row }) => (
        <Text>{row.original.purpose.replace(/Payout/g, "")}</Text>
      ),
    }),
    columnHelper.accessor("account.client.metadata.totalPayouts", {
      header: "Tot. Payouts",
      cell: ({ row }) => (
        <Text wordBreak={"break-all"} noOfLines={2}>
          {formatMoney(
            row.original.account?.client?.metadata?.totalPayouts || 0
          )}
        </Text>
      ),
    }),
    columnHelper.accessor("account.client.lifetimeValue", {
      header: "Lifetime Value",
      cell: ({ row }) => (
        <Text wordBreak={"break-all"} noOfLines={2}>
          {formatMoney(row.original.account?.client?.lifetimeValue || 0)}
        </Text>
      ),
    }),
    columnHelper.accessor("createdAt", {
      header: "Created At",
      cell: ({ row }) => <DateTime isoString={row.original.createdAt} />,
    }),
  ];

  const { getHeaderGroups, getRowModel, resetExpanded } = useReactTable({
    columns: tableColumns,
    data: queryData.data?.docs || [],
    getRowCanExpand: () => true,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  React.useEffect(() => {
    if (queryData && page > queryData.data?.totalPages) {
      setPage(1);
    }
    resetExpanded();
  }, [queryData, page, resetExpanded]);

  return (
    <QueryClientProvider client={queryClient}>
      <TableContainer
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "calc(100vh - 50px)",
          gap: "1rem",
          overflow: "hidden",
        }}
      >
        <HStack
          justify="space-evenly"
          gap="10px"
          colSpan={getHeaderGroups()[0].headers.length}
        >
          <Flex flexGrow={1} gap="10px">
            <InputGroup
              border={"1px solid"}
              borderColor={"#0A1129"}
              rounded={"lg"}
              bg={"#111D45"}
            >
              <InputLeftElement>
                <MdSearch color="gray.500" />
              </InputLeftElement>
              <Input
                color={"white"}
                placeholder="Search..."
                onChange={(e) => {
                  debouncedSetFilter(e.target.value);
                }}
              />
            </InputGroup>
            <CheckboxMenu
              items={checkboxStatusItems}
              selectedValues={filters.status}
              indicateSelection={true}
              onChange={(status) => {
                setPage(1);
                setFilters({ ...filters, status });
              }}
            >
              Status
            </CheckboxMenu>
          </Flex>
          <Flex grow={1} justifyContent="flex-end">
            <Button
              bg="#111D45"
              border={"1px solid"}
              borderColor={"#0A1129"}
              rounded={"lg"}
              color="white"
              size="md"
              onClick={onOpen}
              leftIcon={<MdAdd color={"#7551FF"} />}
            >
              Add Payment
            </Button>
          </Flex>
        </HStack>
        <Skeleton
          isLoaded={isFetched}
          rounded={"xl"}
          flexGrow={1}
          overflowY={"scroll"}
          w="100%"
          bg={"#111D45"}
        >
          <Table size="sm" fontSize="xs">
            <Thead
              css={{
                position: "-webkit-sticky",
              }}
              position="sticky"
              top="0"
              zIndex="1"
              bg={"#111D45"}
            >
              {getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id} p={"10px"}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {getRowModel().rows.map((row) => (
                <React.Fragment key={"fragment-" + row.id}>
                  <Tr key={"row-" + row.id} margin="10px">
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        whiteSpace={"wrap"}
                        p={"10px"}
                        borderBottomWidth={row.getIsExpanded() ? "0" : "1px"}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </Tr>
                  {row.getIsExpanded() && row.getCanExpand() && (
                    <Tr key={"row-exp-" + row.id} w="full">
                      <Td
                        borderBottomWidth={row.getIsExpanded() ? "1px" : "0"}
                        borderColor={
                          row.getIsExpanded()
                            ? "var(--chakra-colors-whiteAlpha-300)"
                            : "0"
                        }
                        colSpan={row.getVisibleCells().length}
                        style={{ overflow: "hidden" }}
                        p="10px"
                      >
                        <ExpandableRow
                          payoutId={row.original.id}
                          payoutStatus={row.original.status}
                          rowData={row.original}
                        />
                      </Td>
                    </Tr>
                  )}
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </Skeleton>
        <Flex>
          {queryData.data && (
            <Pagination
              setPage={setPage}
              data={queryData}
              setPageSize={setPageSize}
            />
          )}
        </Flex>
        <NewPayout isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      </TableContainer>
    </QueryClientProvider>
  );
}
