import { Box, HStack, Text, VStack } from "@chakra-ui/react";

const renderView = (data) => {
  if (!data) {
    return null;
  }
  return data.map(({ items, title }, idx) => {
    let titleComponent = null;
    if (title) {
      titleComponent = (
        <Box py={2} w="full" h="auto" isTruncated>
          {typeof title === "string" ? (
            <Text
              wordBreak="no-break"
              isTruncated
              fontSize={"sm"}
              w="full"
              h="4"
              fontWeight={700}
            >
              {title}
            </Text>
          ) : null}
        </Box>
      );
    }

    return (
      <VStack spacing={0} key={idx}>
        {titleComponent}
        {items.map((item, index) => {
          return item.value === 0 || item.value ? (
            <HStack
              key={index}
              spacing={0}
              gap={0}
              w="full"
              className="alt-row"
            >
              <Text
                wordBreak="break-all"
                isTruncated
                py={0.5}
                px={2}
                w="35%"
                minW="35%"
                h="full"
                fontWeight={600}
              >
                {item.label}
              </Text>
              <Box py={0.5} px={2} w="full" h="full" isTruncated>
                {item.value}
              </Box>
              ,
            </HStack>
          ) : null;
        })}
      </VStack>
    );
  });
};
const BasicMetricView = ({ data }) => {
  if (!Array.isArray(data)) {
    data = [data];
  }
  return renderView(data);
};

export default BasicMetricView;
